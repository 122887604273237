/* stylelint-disable css-modules/no-global-scoped-selector */
@import '_variables.less';
@import '_modals.less';
@import '_type.less';
@import '_notifications.less';

// Links
a {
  color: @link-color;
  font-weight: @proxima-semi-bold;
  text-decoration: @link-decoration;

  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    cursor: pointer;
  }
}

.block {
  display: block;
}

// 5-col grid classes
.col-xs-5ths {
  width: 20%;
  float: left;
}
.col-sm-5ths {
  @media (min-width: 768px) {
    width: 20%;
    float: left;
  }
}

// override some zola-ui styles in toasts with extra content
.large-dek-toast {
  .mobile({
    max-width: 90vw;
  });
  .zui-toast__content {
    width: 100%;
  }
  .zui-toast__headline {
    width: inherit;
  }
  .zui-toast__dek {
    width: inherit;
    white-space: pre-wrap;
  }
}
