// These are legacy classes we are trying to move away from, but we need to remove the zola-ui specificity for now
.spacing(@property) {
  &-primary {
    @{property}: @primary-spacing;
  }
  &-secondary {
    @{property}: @secondary-spacing;
  }
  &-tertiary {
    @{property}: @tertiary-spacing;
  }
  &-quaternary {
    @{property}: @quaternary-spacing;
  }
  &-none {
    @{property}: 0;
  }
}
.spacing(@property1, @property2) {
  &-primary {
    @{property1}: @primary-spacing;
    @{property2}: @primary-spacing;
  }
  &-secondary {
    @{property1}: @secondary-spacing;
    @{property2}: @secondary-spacing;
  }
  &-tertiary {
    @{property1}: @tertiary-spacing;
    @{property2}: @tertiary-spacing;
  }
  &-quaternary {
    @{property1}: @quaternary-spacing;
    @{property2}: @quaternary-spacing;
  }
  &-none {
    @{property1}: 0;
    @{property2}: 0;
  }
}
.m {
  .spacing(margin);
  &t {
    .spacing(margin-top);
  }
  &b {
    .spacing(margin-bottom);
  }
  &l {
    .spacing(margin-left);
  }
  &r {
    .spacing(margin-right);
  }
  &x {
    .spacing(margin-right, margin-left);
  }
  &y {
    .spacing(margin-top, margin-bottom);
  }
}
.p {
  .spacing(padding);
  &t {
    .spacing(padding-top);
  }
  &b {
    .spacing(padding-bottom);
  }
  &l {
    .spacing(padding-left);
  }
  &r {
    .spacing(padding-right);
  }
  &x {
    .spacing(padding-right, padding-left);
  }
  &y {
    .spacing(padding-top, padding-bottom);
  }
}

.marketplace__flex-row {
  display: flex;
  height: 100%;
}

.marketplace-layout {
  width: 100vw;
  .spacing(@property) {
    &-primary {
      @{property}: @primary-spacing;
    }
    &-secondary {
      @{property}: @secondary-spacing;
    }
    &-tertiary {
      @{property}: @tertiary-spacing;
    }
    &-quaternary {
      @{property}: @quaternary-spacing;
    }
    &-none {
      @{property}: 0;
    }
  }
  .spacing(@property1, @property2) {
    &-primary {
      @{property1}: @primary-spacing;
      @{property2}: @primary-spacing;
    }
    &-secondary {
      @{property1}: @secondary-spacing;
      @{property2}: @secondary-spacing;
    }
    &-tertiary {
      @{property1}: @tertiary-spacing;
      @{property2}: @tertiary-spacing;
    }
    &-quaternary {
      @{property1}: @quaternary-spacing;
      @{property2}: @quaternary-spacing;
    }
    &-none {
      @{property1}: 0;
      @{property2}: 0;
    }
  }
  .m {
    .spacing(margin);
    &t {
      .spacing(margin-top);
    }
    &b {
      .spacing(margin-bottom);
    }
    &l {
      .spacing(margin-left);
    }
    &r {
      .spacing(margin-right);
    }
    &x {
      .spacing(margin-right, margin-left);
    }
    &y {
      .spacing(margin-top, margin-bottom);
    }
  }
  .p {
    .spacing(padding);
    &t {
      .spacing(padding-top);
    }
    &b {
      .spacing(padding-bottom);
    }
    &l {
      .spacing(padding-left);
    }
    &r {
      .spacing(padding-right);
    }
    &x {
      .spacing(padding-right, padding-left);
    }
    &y {
      .spacing(padding-top, padding-bottom);
    }
  }

  .marketplace__flex-row {
    display: flex;
    height: 100%;
  }
}
