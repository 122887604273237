/* stylelint-disable css-modules/no-global-scoped-selector */
@import (reference) '~@zola/zola-ui/src/styles/common/_layout.less';

// TODO: Remove legacy bootstrap resets & helper classes
@import 'lib/bootstrap.css';

// Colors that zola-ui doesn't yet have
@color-background-jewel-8: #ebf4f9;
@color-text-gray: #505050;

// Zola UI styles
@import '~@zola/zola-ui/src/styles/common/_reset.less';
@import '~@zola/zola-ui/src/styles/common/_scaffolding.less';
@import '~@zola/zola-ui/src/styles/common/_type.less';
@import '~@zola/zola-ui/src/styles/common/_variables.less';
@import '~@zola/zola-ui/src/styles/common/_mixins.less';
@import '~@zola/zola-ui/src/_font.less';
@import '~@zola/zola-ui/src/_zolaUiIcons.less';

// The Emotion styling for the ToastV2 component doesn't import correctly
@import '~@zola/zola-ui/src/components/Toasts/Toasts.less';
@import '~@zola/zola-ui/src/components/Toasts/Toast.less';

// The Less loader doesn't import these for some reason
@import '~@zola/zola-ui/src/components/Carousel/slick.css';
@import '~@zola/zola-ui/node_modules/react-day-picker/lib/style.css';

// Utils
@import 'util/_main.less';
@import 'common/_main.less';

// STYLING FOR NOW
html {
  position: relative;
  min-height: 100%;
  font-weight: @font-weight-regular;
}

body {
  background-color: @color-white;
}

.banner {
  .not-desktop({
    // zola-ui banner has a fixed 130px on each side, regardless of width
    padding-left: 20px;
    padding-right: 20px;
  });
}

// It looks like the zola-ui reset stylesheet doesn't cover this one
p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 0 11px;
}
