@modal-inner-padding: 20px;
@modal-title-padding: @modal-inner-padding;
@modal-sm: 500px;
@modal-md: @screen-sm-min - 20px; // make sure we have 10px of room on either side
@modal-lg: 900px;
@modal-xl: @screen-lg-min - 20px; // add an XL modal size that we can scale up to on large screens

// special media query for XL screens, takes into account width and height
@screen-xl-media-query: ~'(min-width: @{screen-lg-min}) and (min-height: 1000px)';

.content-top {
  border-bottom: solid 1px @color-outline-gray;
}

.label-secondary {
  background-color: @color-navy;
}

.spoof-header {
  background-color: @color-red;
  padding: 10px;
  text-align: center;
  h1 {
    color: @color-white;
    margin: 0;
  }
}

.modal {
  overflow: scroll;
}

// adding style for modal-close button
.modal-close {
  // reset button styles
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;

  // position it
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 5px;

  // size it
  @modal-close-size: 36px;

  font-size: @modal-close-size;
  line-height: 1;
  height: @modal-close-size;
  width: @modal-close-size;

  // style it
  font-weight: @font-weight-light;
  color: @color-link-gray;
  &:hover {
    color: @link-hover-color;
  }
}

// for XL screens, expand XL modals
.modal-xl {
  @media (min-width: @screen-md-min) {
    width: @modal-lg;
  }
  @media @screen-xl-media-query {
    width: @modal-xl;
    .modal-content {
      padding: @modal-inner-padding; // add some additional padding for XL modals
    }
  }
}
