.humane,
.humane-zola {
  position: fixed;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  filter: progid:dximagetransform.microsoft.alpha(Opacity=100); // stylelint-disable-line
  @media (max-width: @screen-xs-max) {
    top: 60px;
    width: 100%;
    left: 0;
    margin-left: 0;
    div {
      width: 100%;
    }
  }

  font-size: 15px;
  top: 80px;
  width: 540px;
  text-align: center;
  left: 50%;
  margin-left: -270px;
  .zolaicon {
    padding-right: 10px;
  }
}
.humane p,
.humane-zola p,
.humane ul,
.humane-zola ul {
  margin: 0;
  padding: 0;
}
.humane ul,
.humane-zola ul {
  list-style: none;
}
.humane a,
.humane-zola a {
  color: @color-white;
  text-decoration: underline;
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 13px;
  font-weight: @font-weight-semibold;
  letter-spacing: 1px;
  &:hover {
    color: @color-secondary-gray;
  }
}
.humane.humane-zola-info div,
.humane-zola.humane-zola-info div {
  width: 100%;
  background-color: @color-secondary-background-gray;
  color: @color-primary-gray;
  position: relative;
  padding: 10px 20px;
  display: inline-block;
}
.humane.humane-zola-success div,
.humane-zola.humane-zola-success div {
  width: 100%;
  background-color: @color-light-navy;
  color: @color-white;
  position: relative;
  padding: 10px 20px;
  display: inline-block;
}
.humane.humane-zola-warning div,
.humane-zola.humane-zola-warning div {
  width: 100%;
  background-color: @color-yellow;
  color: @color-primary-gray;
  position: relative;
  padding: 10px 20px;
  display: inline-block;
}
.humane.humane-zola-error div,
.humane-zola.humane-zola-error div {
  width: 100%;
  background-color: @color-red;
  color: @color-white;
  position: relative;
  padding: 10px 20px;
  display: inline-block;
}
.humane.humane-zola-confirmation div,
.humane-zola.humane-zola-confirmation div {
  width: 100%;
  background-color: @color-light-navy;
  color: @color-white;
  position: relative;
  padding: 10px 20px;
  display: inline-block;
}
.humane-enter,
.humane-zola.humane-zola-enter {
  opacity: 0.1;
}
.humane-enter-active,
.humane-zola.humane-zola-enter-active {
  opacity: 1;
}
.humane-exit,
.humane-zola.humane-zola-exit {
  opacity: 1;
}
.humane-exit-active,
.humane-zola.humane-zola-exit-active {
  opacity: 0.1;
  transition: all 0.6s ease-in-out;
  z-index: 100000;
  filter: progid:dximagetransform.microsoft.alpha(Opacity=100); // stylelint-disable-line
}
